<template>
    <div>
        <b-card class="rounded-lg">
            <b-card-text class="text-center">
                <h2>
                    {{ $t('FaqCard.title') }}
                </h2>

                <div class="d-flex justify-content-center">
                    <p class="text-center font-small-3 mb-0" style="width: 33%">
                        {{ $t('FaqCard.subtitle') }}
                    </p>
                </div>
            </b-card-text>
            <b-card-text class="">
                <h2>{{ $t('FaqCard.categories') }}</h2>
                <b-card no-body>
                    <Loading v-if="categories.length == 0" />
                    <b-tabs v-else pills card>
                        <b-tab
                            v-for="(data, index) in categories"
                            :key="index"
                            :title="data[categoriesLang]"
                            @click="tabsAction(data.id)"
                        >
                            <b-card-text>
                                <div class="accordion" role="tablist">
                                    <Loading v-if="faqLoading" />
                                    <template v-else>
                                        <span v-if="currentFaq.length > 0">
                                            <b-card
                                                no-body
                                                class="mb-1 border rounded-lg"
                                                v-for="(
                                                    data, index
                                                ) in currentFaq"
                                                :key="index"
                                            >
                                                <b-card-header
                                                    header-tag="header"
                                                    class="p-1"
                                                    role="tab"
                                                >
                                                    <b-button
                                                        block
                                                        v-b-toggle="
                                                            'accordion-productdetails' +
                                                            index
                                                        "
                                                        variant="transparent"
                                                        class="px-1"
                                                        @click="
                                                            data.openTabs =
                                                                !data.openTabs
                                                        "
                                                    >
                                                        <div
                                                            class="d-flex justify-content-between"
                                                        >
                                                            <p
                                                                class="mb-0"
                                                                v-text="
                                                                    data[
                                                                        questionLang
                                                                    ]
                                                                "
                                                            />

                                                            <feather-icon
                                                                size="20"
                                                                :icon="
                                                                    data.openTabs
                                                                        ? 'PlusIcon'
                                                                        : 'MinusIcon'
                                                                "
                                                            />
                                                        </div>
                                                    </b-button>
                                                </b-card-header>
                                                <b-collapse
                                                    :id="
                                                        'accordion-productdetails' +
                                                        index
                                                    "
                                                    :visible="index == 0"
                                                    :accordion="
                                                        'productdetails-accordion' +
                                                        index
                                                    "
                                                    role="tabpanel"
                                                >
                                                    <b-card-body
                                                        class="pt-0"
                                                        style="
                                                            padding-left: 27px;
                                                        "
                                                    >
                                                        <b-card-text
                                                            v-text="
                                                                data[answerLang]
                                                            "
                                                        >
                                                        </b-card-text>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </span>

                                        <b-card v-else class="mb-1 rounded-lg">

                                            <Loading />
                                        </b-card>
                                    </template>
                                </div>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig';
export default {
    data() {
        return {
            faqList: [],
            categories: [],
            currentFaq: [],
            faqLoading: true,
            config: useAppConfig(),
        };
    },

    computed: {
        answerLang() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_answer' : 'eng_answer';
            } else return this.getLang == 'sv' ? 'swe_answer' : 'eng_answer';
        },
        questionLang() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_question' : 'eng_question';
            } else
                return this.getLang == 'sv' ? 'swe_question' : 'eng_question';
        },
        categoriesLang() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_category' : 'eng_category';
            } else
                return this.getLang == 'sv' ? 'swe_category' : 'eng_category';
        },
        getLang() {
            return this.config.lang.value;
        },
    },

    methods: {
        tabsAction(value) {
            let newCurrentFaq = [];

            let faqUuid = this.faqList.filter(
                element => element.category.id == value
            );

            if (faqUuid.length > 0) {
                faqUuid.forEach(items => {
                    this.$useJwt.faqList(items.uuid).then(res => {
                        newCurrentFaq.push(res.data);
                    });
                });
                this.currentFaq = newCurrentFaq;
            } else this.currentFaq = '';

            this.faqLoading = false;
        },

        init() {
            this.$useJwt.faqCategories().then(res => {
                const {results} = res.data;
                this.categories = results;
                let firstCategories = results[0].id;

                this.$useJwt.faqList().then(res => {
                    const {results} = res.data;

                    results.forEach(element => {
                        this.faqList.push({...element, openTabs: false});
                    });

                    let getFaq = this.faqList.filter(
                        list => list.category.id == firstCategories
                    );

                    this.currentFaq = getFaq;
                    this.faqLoading = false;
                });
            });
        },
    },

    mounted() {
        this.init();
    },
};
</script>

<style scoped></style>
